@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.googleMapContainer{
  width: 100%;
  max-width: 100%;
  height: 300px;

  @include mix.max-media(var.$screen-sm){
    height: 250px;
  }
}