@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//TOP_firstView
// ============================================================

.top_firstView{
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  padding-top: var.$header_H_PC +  20px;

  &:before{
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 70%;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 31%, rgba(6,164,224,1) 93%);
  }

  &:after{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: -1;
    background: url("../img/mv/firstView_bg.webp") no-repeat center bottom;

    @include mix.min-media(1700px){
      background-size: contain;
    }
  }


  //img {
  //  width: 100%;
  //  max-width: 100%;
  //  height: 100%;
  //  object-fit: cover;
  //}

  //&.forSP{
  //  @include mix.min-media(var.$screen-md){
  //    display: none;
  //  }
  //}
  //
  //&.forPC{
  //  display: none;
  //  @include mix.min-media(var.$screen-md){
  //    display: block;
  //  }
  //}
}

.top_firstViewInner{
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  @include mix.min-media(var.$screen-sm){
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.top_firstViewInner_text{
  min-width: 180px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform : translate(-50%,-50%);
}

.top_firstViewInner_textTitle{
  writing-mode: vertical-rl;
  font-family: var.$font-family-rounded;
  font-size: 24px;
  filter: drop-shadow(2px 4px 4px #fff);
}

//採用情報

.top_fvRecruitUnitWrapper{
  position: absolute;
  left: 50%;
  bottom: 2%;
  transform : translate(-50%,-50%);
  width: 95%;
  border-radius: 5px;
  background-color: #B28247;
  padding: 4px;
  max-width: 700px;
}

.top_fvRecruitUnit{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;
  //
  width: 100%;
  max-width: 100%;
  border: 1px solid #fff;
  border-radius: 5px;
}

.top_fvRecruitTitle{
  width: 20%;
  font-family: var.$font-family-rounded;
  font-size: 12px;
  color: var.$text-color-white;
  text-align: center;
}

.top_fvRecruitLeadGroup{
  width: 80%;
  border-left: 1px solid #ffffff;
}

.top_fvRecruitLead{
  font-family: var.$font-family-rounded;
  font-size: 14px;
  color: var.$text-color-white;
  text-align: center;
}


// ============================================================
//page_mv
// ============================================================

.page_mv{
  width: 100%;
  max-width: 100%;
  min-height: 180px;
  padding-top: var.$header_H_SP ;

  @include mix.min-media(var.$screen-sm-min){
    padding-top: var.$header_H_PC;
  }

}

.page_mv_inner{
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 240px;
  overflow: hidden;
}

.page_mvBg{
  position: relative;
  z-index: -1;
  width: 100%;
  max-width: 100%;

  img{
    width: 100%;
    max-width: 100%;
  }
}

.page_mv_heading{
  position: absolute;
  min-width: 200px;
  top: 60px;
  left: 50%;
  transform : translate(-50%,-50%);
  font-family: var.$font-family-rounded;
  font-size: 22px;
  text-align: center;
  background-color: #fff;

  @include mix.min-media(var.$screen-sm-min){
    top: 80px;
  }

  @include mix.min-media(var.$screen-md-min){
    top: 120px;
  }
}