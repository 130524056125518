@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.entryContentsBody{

  .text01{
    font-size: 16px;
    font-family: var.$font-family-rounded;
    font-weight: 500;
    margin-bottom: 12px;

    &:last-child{
      margin-bottom: 0;
    }


    @include mix.max-media(var.$screen-sm){
      font-size: 13px;
    }

    &_center{
      text-align: center;
    }

    &_right{
      text-align: right;
    }

  }

//丸つきリスト
.ul01{
  list-style: none;
  width: 100%;
  max-width: 100%;
  font-family: var.$font-family-rounded;
  font-size: 14px;
  margin-bottom: 12px;

  li{
    position: relative;
    padding-left: 1.2em;
    margin-bottom: 8px;

    &:last-child{
      margin-bottom: 0;
    }

    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 6px;
      transform : translate(-50%,-50%);
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: var.$brand-main-darker;
      border: none;
    }
  }
}

  //何もついていないリスト

  .ul02{
    list-style: none;
    width: 100%;
    max-width: 100%;
    font-family: var.$font-family-rounded;
    font-size: 14px;
    margin-bottom: 12px;

    li{
      margin-bottom: 8px;

      &:before{
        border: none;
      }

      &:last-child{
        margin-bottom: 0;
      }
    }
  }



}