@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

body{
  background: var.$brand-main;
}

// ============================================================
// wrapper
// ============================================================
.wrapper{
  position: relative;
  //max-width: 800px;
  //margin: 0 auto;
}

// ============================================================
// container
// ============================================================

.container{
  width: 100%;
  max-width: 100%;
  background: var.$brand-main;
}

.top_newsContainer{
  background: #EFEFEF;
}

// ============================================================
// container_inner
// ============================================================

$container_w       : 900px;
$container_pad_PC  : 50px;
$container_pad_TAB : 20px;
$container_pad_SP  : 20px;

.container_inner{
  position: relative;
  max-width: $container_w + $container_pad_PC;
  min-width: $container_w + $container_pad_PC;
  width: 100%;
  padding: $container_pad_PC $container_pad_PC  ;
  margin: 0 auto;
  @include mix.max-media(var.$screen-mlg){
    max-width: 100%;
    min-width: 100%;
    padding-left: $container_pad_TAB;
    padding-right: $container_pad_TAB;
  }
  @include mix.max-media(var.$screen-sm){
    padding-top: var.$header_H_SP + 30px;
    padding-left: $container_pad_SP;
    padding-right: $container_pad_SP;
    padding-bottom: 130px;
  }
}


.page_containerInner{
  padding-top: 20px!important;
  padding-bottom: 20px!important;

  @include mix.min-media(var.$screen-sm){
    padding-top: 50px!important;
  }
}



// ============================================================
// 各ページごとの固有設定用innerクラス
// ============================================================'

$top_containerInnerTop_pad_PC  : 50px;
$top_containerInnerTop_pad_TAB : 20px;
$top_containerInnerTop_pad_SP  : 20px;

$index_containerInnerTop_pad_PC  : 50px;
$index_containerInnerTop_pad_TAB : 20px;
$index_containerInnerTop_pad_SP  : 20px;

$entry_containerInnerTop_pad_PC  : 50px;
$entry_containerInnerTop_pad_TAB : 20px;
$entry_containerInnerTop_pad_SP  : 20px;


.oneday_containerInner{
  @include mix.min-media(var.$screen-sm){
    max-width: 700px;
  }
}