@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.facilities_imgGroup{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  gap: 16px;
  width: 100%;
  max-width: 100%;

  @include mix.max-media(var.$screen-sm){
    gap: 8px;
  }


}

.facilities_img{
  width: calc((100% - 16px) / 2);
  max-width: 100%;
  height: auto;

  @include mix.max-media(var.$screen-5ls){
    width: calc((100% - 8px) / 2);
  }


  img{
    width: 100%;
    max-width: 100%;
  }
}