@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
// README
// ============================================================
//
// HTML SAMPLE
//
//  <div id="menuTrigger" class="btn">ボタン</div>
//    <ul id="offcanvas" class="offcanvasMenu">
//      <li>メニュー</li>
//      <li>メニュー</li>
//      <li>メニュー</li>
//      <li>メニュー</li>
//      <li>メニュー</li>
//      <li>メニュー</li>
//    </ul>
//  </div>
//
// # 備考
// ヘッダーメニューボタン（ハンバーガーメニュー）に対してid="menuTrigger"を付与
// グローバルナビゲーション要素に対して id="offcanvas" を付与する必要が条件
// 背景レイヤー（offcanvasBg）はJS側で自動生成されます
// 開閉アニメーションについてはCSSで全て設定しているので、アニメーションを変更する際はCSSで調整すること
//
// ============================================================

// Config
//
// 以下で設定する$BLAKEPOINT_HEADER_SP_MODEがoffcanvas処理が発火するブレイクポイントとなる
// 以下のブレイクポイントを変更する場合は /src/assets/js/function/_app.js の以下のコードの値を$BLAKEPOINT_HEADER_SP_MODEと同じにする必要がある
//
// let BLAKEPOINT_HEADER_SP_MODE = 1024;
//
    $BLAKEPOINT_HEADER_SP_MODE : 2000px;
//
// -------------------

.offcanvasTrigger{
  z-index: var.$zindex_offcanvas_trigger;
  position: absolute;
  top: 10px;
  right: 10px;
}

// -------------------
// 構造
// -------------------

.js_isOffcanvasActive{
  max-width:100%;

  .offcanvasTrigger{
    position: fixed;
  }
}


// 見た目
// -------------------

.offcanvasMenu{
  width: 50%;
  @include mix.transitionDelay(.3s);
  display: block;
  background-color: var.$brand-main;

  @include mix.max-media(var.$screen-md){
    width: 70%;
  }
  
  @include mix.max-media(var.$screen-sm){
    width: 100%;

  }
}

// 構造
// -------------------

.offcanvasMenu{
  @include mix.max-media($BLAKEPOINT_HEADER_SP_MODE) {
    z-index: var.$zindex_offcanvas_menu;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    transform: translate(-100%, 0);
    padding-top: var.$header_H_PC;
  }

  @include mix.max-media(var.$screen-sm){
    padding-top: var.$header_H_SP;
  }
}

.js_isOffcanvasActive .offcanvasMenu{
  transform : translate(0%,0);
}

// 見た目
// -------------------

.offcanvasBg{
  @include mix.max-media($BLAKEPOINT_HEADER_SP_MODE) {
    background: var.$background_layer_color;
    @include mix.transitionDelay(.4s);
  }
}

// 構造
// -------------------

.offcanvasBg{
  @include mix.max-media($BLAKEPOINT_HEADER_SP_MODE) {
    z-index: var.$zindex_offcanvas_bg;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    transform: translate(-100%, 0);
  }
}

.js_isOffcanvasActive .offcanvasBg{
  transform : translate(0%,0);
}

// ============================================================
// オフキャンバストリガー
// ============================================================

// 見た目
// ------------------------------------------------------------

$trigger_color: #000;
$trigger_color_active: #000;
$triggerW: 25px;
$triggerH: 20px;
$triggerWSP: 21px;
$triggerHSP: 15px;

.offcanvasTrigger {
  z-index: var.$zindex_offcanvas_trigger;
  //

    position: absolute;
    display: flex;
    //
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    row-gap: 9px;
    //
    width: var.$header_H_PC;
    height: var.$header_H_PC;
    background: var.$brand-main;
    //
    top: 0;
    left: 0;
    cursor: pointer;
    padding-top: 8px;


  @include mix.max-media(var.$screen-sm) {
    width: var.$header_H_SP;
    height: var.$header_H_SP;
    padding-top: 0;
    row-gap: 4px;
  }


  p{
    font-size: 12px;
    color: #000;
    margin: 0;
    line-height: 1.1;
  }
}

.offcanvasTriggerBtn,
.offcanvasTriggerBtn span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  @include mix.resetBtnStyle();
}
.offcanvasTriggerBtn {
  position: relative;
  width: $triggerW;
  height: $triggerH;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;

  // @include mix.max-media(var.$screen-sm){
  //   width: $triggerWSP;
  //   height: $triggerHSP;
  // }
}
.offcanvasTriggerBtn span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $trigger_color;
}
.offcanvasTriggerBtn span:nth-of-type(1) {
  top: 0;
}
.offcanvasTriggerBtn span:nth-of-type(2) {

    top: 45%;

}
.offcanvasTriggerBtn span:nth-of-type(3) {
  bottom: 0;
}

.js_isOffcanvasActive .offcanvasTriggerBtn span:nth-of-type(1) {
  transform: translateY(9px) rotate(-45deg);
  background-color: $trigger_color_active;

  // @include mix.max-media(var.$screen-sm){
  //   transform: translateY(7px) rotate(-45deg);
  // }
}
.js_isOffcanvasActive .offcanvasTriggerBtn span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
  animation: active-menu05-bar02 0.8s forwards;
}
@keyframes active-menu05-bar02 {
  100% {
    height: 0;
  }
}
.js_isOffcanvasActive .offcanvasTriggerBtn span:nth-of-type(3) {
  transform: translateY(-9px) rotate(45deg);
  background-color: $trigger_color_active;

  // @include mix.max-media(var.$screen-sm){
  //   transform: translateY(-7px) rotate(45deg);
  // }
}

