@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.headingUnit{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.heading02{
  font-family: var.$font-family-rounded;
  font-size: 24px;
  color: var.$text-color;
}

//下線付きheading

.heading03{
  font-family: var.$font-family-rounded;
  font-size: 20px;
  line-height: 1.1;
  text-align: center;
  border-bottom: 1px solid #000;
  padding : 0 48px 8px;
  margin-bottom: 24px;
}

//ロゴつきheading

.heading04{
  position: relative;
  display: inline-block;
  font-family: var.$font-family-rounded;
  font-size: 20px;
  line-height: 1.1;
  text-align: center;
  padding : 8px 0;
  margin-bottom: 24px;

  &::before{
    content: url("../img/common/heading_logo.svg")  ;
    position: absolute;
    top: 50%;
    left: -1.4em;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }
}

//オレンジ背景色heading

.heading05{
  font-family: var.$font-family-rounded;
  color: var.$text-color-white;
  font-size: 20px;
  line-height: 1.1;
  text-align: center;
  padding : 8px 0;
  margin-bottom: 24px;
  background-color: var.$brand-accent;
  border-radius: 2em;
}

//オレンジ横棒付きheading

.heading06{
  font-family: var.$font-family-rounded;
  color: var.$brand-accent;
  font-size: 20px;
  line-height: 1.4;
  padding : 8px 0 8px 16px;
  margin-bottom: 24px;
  background-color: var.$text-color-white;
  border-left: 4px solid var.$brand-accent;
}


//top専用heading

.top_heading{
  font-family:var.$font-family-rounded;
  font-size: 20px;
  text-align: center;
  line-height: 1.1;
  margin-bottom: 24px;

  @include mix.max-media(var.$screen-sm){
    font-size: 16px;
    margin-bottom: 16px;
  }
}