@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


.custom_marginUnit{
  opacity: 0;
  margin-top: 0!important;
}

.margin_lg{
  margin-bottom: 80px;

  @include mix.max-media(var.$screen-md){
    margin-bottom: 40px;
  }

  @include mix.max-media(var.$screen-sm){
    margin-bottom: 24px;
  }
}

.margin_md{
  margin-bottom: 64px;

  @include mix.max-media(var.$screen-md){
    margin-bottom: 32px;
  }

  @include mix.max-media(var.$screen-sm){
    margin-bottom: 24px;
  }
}

.margin_sm{
  margin-bottom: 48px;

  @include mix.max-media(var.$screen-md){
    margin-bottom: 24px;
  }

  @include mix.max-media(var.$screen-sm){
    margin-bottom: 16px;
  }
}