@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//  README
// ------------------------------------------------------------
//  ユーティリティ用のパティングクラスを使用する場合「$utilityPaddingClass」を「true」にし
//  必要な各マージンクラスを「paddingTop/paddingBottom/...」をtrueにする
//  $endValueはpxの最大値を表す
// ------------------------------------------------------------

$utilityPaddingClass: true !default;
$paddingTop: true !default;
$paddingBottom: true !default;
$paddingLeft: false !default;
$paddingRight: false !default;
$endValue: 100 !default;

// padding class
// ------------------------------------------------------------

@if $utilityPaddingClass {
  @for $i from 0 through $endValue {
    @if $paddingTop {
      .pt_#{$i} {
        padding-top: $i + px;
      }
      @include mix.max-media(var.$screen-xlg) {
        .pt_#{$i}_xlg {
          padding-top: $i + px;
        }
      }
      @include mix.max-media(var.$screen-lg) {
        .pt_#{$i}_lg {
          padding-top: $i + px;
        }
      }
      @include mix.max-media(var.$screen-mlg) {
        .pt_#{$i}_mlg {
          padding-top: $i + px;
        }
      }
      @include mix.max-media(var.$screen-slg) {
        .pt_#{$i}_slg {
          padding-top: $i + px;
        }
      }
      @include mix.max-media(var.$screen-md) {
        .pt_#{$i}_md {
          padding-top: $i + px;
        }
      }
      @include mix.max-media(var.$screen-6ls) {
        .pt_#{$i}_6ls {
          padding-top: $i + px;
        }
      }
      @include mix.max-media(var.$screen-5ls) {
        .pt_#{$i}_5ls {
          padding-top: $i + px;
        }
      }
      @include mix.max-media(var.$screen-sm) {
        .pt_#{$i}_sm {
          padding-top: $i + px;
        }
      }
      @include mix.max-media(var.$screen-msm) {
        .pt_#{$i}_msm {
          padding-top: $i + px;
        }
      }
      @include mix.max-media(var.$screen-xs) {
        .pt_#{$i}_xs {
          padding-top: $i + px;
        }
      }
    }
    @if $paddingBottom {
      .pb_#{$i} {
        padding-bottom: $i + px;
      }
      @include mix.max-media(var.$screen-xlg) {
        .pb_#{$i}_xlg {
          padding-bottom: $i + px;
        }
      }
      @include mix.max-media(var.$screen-lg) {
        .pb_#{$i}_lg {
          padding-bottom: $i + px;
        }
      }
      @include mix.max-media(var.$screen-mlg) {
        .pb_#{$i}_mlg {
          padding-bottom: $i + px;
        }
      }
      @include mix.max-media(var.$screen-slg) {
        .pb_#{$i}_slg {
          padding-bottom: $i + px;
        }
      }
      @include mix.max-media(var.$screen-md) {
        .pb_#{$i}_md {
          padding-bottom: $i + px;
        }
      }
      @include mix.max-media(var.$screen-6ls) {
        .pb_#{$i}_6ls {
          padding-bottom: $i + px;
        }
      }
      @include mix.max-media(var.$screen-5ls) {
        .pb_#{$i}_5ls {
          padding-bottom: $i + px;
        }
      }
      @include mix.max-media(var.$screen-sm) {
        .pb_#{$i}_sm {
          padding-bottom: $i + px;
        }
      }
      @include mix.max-media(var.$screen-msm) {
        .pb_#{$i}_msm {
          padding-bottom: $i + px;
        }
      }
      @include mix.max-media(var.$screen-xs) {
        .pb_#{$i}_xs {
          padding-bottom: $i + px;
        }
      }
    }
    @if $paddingLeft {
      .pl_#{$i} {
        padding-left: $i + px;
      }
      @include mix.max-media(var.$screen-xlg) {
        .pl_#{$i}_xlg {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-lg) {
        .pl_#{$i}_lg {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-mlg) {
        .pl_#{$i}_mlg {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-slg) {
        .pl_#{$i}_slg {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-md) {
        .pl_#{$i}_md {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-6ls) {
        .pl_#{$i}_6ls {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-5ls) {
        .pl_#{$i}_5ls {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-sm) {
        .pl_#{$i}_sm {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-msm) {
        .pl_#{$i}_msm {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-xs) {
        .pl_#{$i}_xs {
          padding-left: $i + px;
        }
      }
    }
    @if $paddingRight {
      .pr_#{$i} {
        margin-right: $i + px;
      }
      @include mix.max-media(var.$screen-xlg) {
        .pr_#{$i}_xlg {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-lg) {
        .pr_#{$i}_lg {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-mlg) {
        .pr_#{$i}_mlg {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-slg) {
        .pr_#{$i}_slg {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-md) {
        .pr_#{$i}_md {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-6ls) {
        .pr_#{$i}_6ls {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-5ls) {
        .pr_#{$i}_5ls {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-sm) {
        .pr_#{$i}_sm {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-msm) {
        .pr_#{$i}_msm {
          padding-left: $i + px;
        }
      }
      @include mix.max-media(var.$screen-xs) {
        .pr_#{$i}_xs {
          padding-left: $i + px;
        }
      }
    }
  }
}