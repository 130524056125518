@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

$container_w       : 900px;
$container_pad_PC  : 50px;
$container_pad_TAB : 20px;
$container_pad_SP  : 20px;

.footer_inner{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background: var.$brand-main;
  column-gap: 24px;
  //max-width: $container_w + $container_pad_PC;
  //min-width: $container_w + $container_pad_PC;
  width: 100%;
  padding: 0  $container_pad_PC ;
  margin: 0 auto;

  @include mix.max-media(var.$screen-mlg){
    //max-width: 100%;
    //min-width: 100%;
    padding-left: $container_pad_TAB;
    padding-right: $container_pad_TAB;
  }

  @include mix.max-media(var.$screen-md){
    flex-flow: column;
    column-gap: 0;
    row-gap: 24px;
  }

  @include mix.max-media(var.$screen-sm){
    padding-left: $container_pad_SP;
    padding-right: $container_pad_SP;
  }
}

.footer_logo{
  width: function.px2per(200,900);
  @include mix.max-media(var.$screen-md){
    width: function.px2per(300,600);
  }
}

.footer_mapQr{
  width: function.px2per(150,900);

  @include mix.max-media(var.$screen-md){
    width: function.px2per(300,600);
    margin: 0 auto;
  }
}

.footer_infoList{
  list-style: none;
}

.footer_infoItem{
  font-family: var.$font-family-rounded;
  font-size: var.$font-size-base;
  line-height: 1.4;
  img{
    width: 100%;
    max-width: 200px;
  }
}

.footer_contactUnit{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;
  //
  //border-top: 2px solid #fff;
  //border-bottom: 2px solid #fff;

  @include mix.max-media(var.$screen-md){
    flex-flow: column wrap;
  }
}

.footer_contactItem{
  width: calc(100% / 2);
  padding: 24px 0;
  max-height: 118px;
  min-height: 118px;

  @include mix.max-media(var.$screen-md){
    width: 100%;
    max-height: none;
    min-height: auto;
  }

}

.footer_contactPhone,.footer_contactMail{

  //border-right: 2px solid #fff;

  a{
    @include mix.setLinkHoverFadeAnime();
    text-decoration: none;
    display: flex;
    flex-flow: row wrap;
  }

}

.footer_contactPhone{
  background: var.$brand-accent;
}

.footer_contactMail{
  background: #22AC38;
}


.contactPhoneNum,.contactMailHeading{
  display: inline-block;
  position: relative;
  font-family: var.$font-family-base;
  font-size: 32px;
  color: var.$text-color-white;
  letter-spacing: .02em;
  text-align: center;
  max-width: 100%;
  line-height: 1.1;
  margin: 0 auto;
  align-self: center;

  @include mix.max-media(var.$screen-md){
    font-size: 28px;
  }

  @include mix.max-media(var.$screen-sm){
    padding-left: 20px;
  }

}

.contactPhoneNum{
  padding-left: 3%;

  @include mix.max-media(var.$screen-md){
    padding-left: 0;
  }
  &:before{
    display: block;
    content: "phone";
    position: absolute;
    top: 50%;
    left: -1em;
    transform : translate(-50%,-50%);
    font-family: "Material Icons Outlined";
    font-size: 20px;
    font-style: normal;
    color: var.$text-color-white;
    border-radius: 50%;
    border: 4px solid var.$text-color-white;
    padding: 5px;

    @include mix.max-media(var.$screen-md){
      left: -2.7em;
    }

    @include mix.max-media(var.$screen-sm){
      left: -2.2em;
    }
  }
}

.contactMailHeading{
  padding-left: 3%;
  //font-size: 26px;
  //
  //@include mix.max-media(var.$screen-md){
  //  font-size: 24px;
  //}
  //
  @include mix.max-media(var.$screen-sm){
    font-size: 22px;
  }

  &:before{
    display: block;
    content: "mail";
    position: absolute;
    top: 50%;
    left: -.8em;
    transform : translate(-50%,-50%);
    font-family: "Material Icons Outlined";
    font-size: 20px;
    font-style: normal;
    color: var.$text-color-white;
    border-radius: 50%;
    border: 4px solid var.$text-color-white;
    padding: 5px;

    @include mix.max-media(var.$screen-sm){
      left: -.7em;
    }
  }


}


.contactItemLead{
  font-family: var.$font-family-base;
  font-size: 14px;
  color: var.$text-color-white;
  letter-spacing: .02em;
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin-top: 12px;
  align-self: center;

  @include mix.max-media(var.$screen-sm){
    margin-top: 0;
    font-size: 10px;
    padding-left: 20px;
  }
}

//footer_companyInfo
// ============================================================

.footer_companyInfo{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 48px 0;

  @include mix.max-media(var.$screen-sm){
    padding: 24px 0;
  }
}

.companyInfo_heading{
  font-family: var.$font-family-rounded;
  font-size: 20px;
  line-height: 1.1;
  text-align: center;
  border-bottom: 1px solid #000;
  padding : 0 48px 8px;
  margin-bottom: 24px;
}

//footer_baundingBox

.baundingBox_inner{
  padding: 24px 0;
}

.footer_baundingBox{
  background: var.$brand-accent;
  max-width: 280px;
  border-radius: 8px;
  margin: 0 auto;
  padding: 8px 16px;


  a{
    @include mix.setLinkHoverFadeAnime();
    text-decoration: none;
    font-family: var.$font-family-rounded;
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: .06em;
    text-align: center;
    color: var.$text-color-white;
  }
}

//copyright

.offcanvas_copyright{
  text-align: center;
  padding-top: 160px;
}
