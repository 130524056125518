@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.scrollDownUnit{
  position: absolute;
  bottom: 13%;
  left: 50%;
  transform : translate(-50%,-50%);
  width: 70px;
  height: 70px;
}

.scrollDownBg{
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: url("../img/common/hexagon.svg") no-repeat center center;
  background-size: cover;

  p{
    position: absolute;
    top: 50%;
    left: 50%;
    transform : translate(-50%,-50%);
    font-family: var.$font-family-rounded;
    font-size: 12px;
    letter-spacing: .08em;
    font-weight: bold;
  }
}

.scrollDown_arrow{
  position: absolute;
  bottom: 30%;
  left: 45%;
  transform : translate(-50%,-50%);
  width: 6px;
  height: 6px;

//  上下に動くアニメーション
  animation: scrollDown 1.5s ease infinite;
//  scrollDownのアニメーション内容
  @keyframes scrollDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }
}