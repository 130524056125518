@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//オフキャンバスメニュー内部
// ============================================================

.offcanvasMenu{
  position: relative;
  font-family: var.$font-family-rounded;
  color: var.$text-color;


  @include mix.min-media(var.$screen-xlg){
    display: none;
  }
}


.offcanvasMenuItem{
  font-size: 24px;
  border-top: 1px solid #DDDDDD;
  padding: 16px 0 16px 40px;

  &:last-of-type{
    border-bottom: 1px solid #DDDDDD;
  }

  &:before {
    content: url("../img/common/triangle.svg");
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }


  a{
    @include mix.setTextLinkColor(var.$text-color);
  }
}

.offcanvasSlaveMenu{
  list-style: none;
  padding-top: 16px;

}

.offcanvasSlaveMenuItem{
  font-size: 18px;
  padding: 8px 0 8px 60px;
}

.offcanvasSecondaryBody{
  padding: 36px 0 16px 40px;
}

.offcanvasSecondaryHeadingUnit{
  p{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    border-left: 4px solid #9FA0A0;
    padding-left: 8px;
  }
}


.offcanvas_copyright{
  p{
    font-family: var.$font-family-rounded;
    color: var.$text-color;
    line-height: 1.1;
  }
}