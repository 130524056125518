@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//presidentUnit
// ============================================================

.presidentUnit{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: start;
  max-width: 360px;
  background-color: #fff;
  padding: 14px 0;
  margin: 0 auto 36px;

  @include mix.min-media(var.$screen-md-min){
    max-width: 540px;
    align-items: start;
  }

  @include mix.min-media(var.$screen-sm-min){
    max-width: 80%;
  }
}

.presidentUnit_body{
  width: 45%;
  padding: 0 12px;
  text-align: center;
}

.presidentUnit_img{
  width: 80%;
  max-width: 100%;
  margin: 0 auto;
  padding: 8px 0;
  
  img{
    width: 100%;
    max-width: 100%;
  }
}

.presidentUnit_position{
  font-family: var.$font-family-rounded;
  font-size: 12px;
}

.presidentUnit_textUnit{
  width: 55%;
  padding: 0 4px;

  p{
    line-height: 1.4;
    font-size: 12px!important;

    @include mix.min-media(var.$screen-md){
      font-size: 15px!important;
      line-height: 1.7;
    }

  }
}

//top用presidentUnit

.top_presidentUnit{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: start;
  max-width: 360px;
  padding: 14px 0;
  margin: 0 auto 36px;

  @include mix.min-media(var.$screen-md-min){
    max-width: 540px;
  }

  @include mix.min-media(var.$screen-sm-min){
    max-width: 80%;
  }
}

.top_presidentUnit_body{
  width: 45%;
  padding: 0 12px;
  text-align: center;
}

//staffUnit
// ============================================================

.staffUnit_group{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;
  column-gap: 12px;
  row-gap: 24px;
}

.staffUnit{
  width: calc((100% - 12px) / 2);

  @include mix.min-media(var.$screen-md){
    width: calc((100% - 24px) / 3);
  }
}

.staffUnit_img{
  width: 100%;
  max-width: 100%;
  padding: 0 12px;
  margin-bottom: 8px;

  img{
    width: 100%;
    max-width: 100%;
  }
}

.staffUnit_body{
  width: 100%;
  padding: 0 8px;
  text-align: center;
}
.presidentUnitNamePlate {
  display: flex;
  flex-flow: column-reverse;
}

.staffUnitNamePlate{
  display: flex;
  flex-flow: column-reverse;
}

.staffUnit_position{
  font-family: var.$font-family-rounded;
  font-size: 12px;
}

.staffUnit_textUnit{

    p{
    @include mix.min-media(var.$screen-sm){
      font-size: 15px!important;
    }
  }
}