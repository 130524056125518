@use "../../variables" as var;
@use "../../mixins" as mix;
@use "../../functions" as function;

//README
//こちらのファイルはa blog cmsのテンプレート用のscssファイルです。
//テンプレートのscssファイルは、
//src/assets/scss/base/_acmsTemplate.scss
//に記述してください。
//開発キットの初期状態では読み込まれないようになっています。
//読み込むには、
//src/assets/scss/base/_index.scss
//のコメントアウトを外してください。
//また、このテンプレートないのミックスインを使用する場合は、
//使用したいファイルの一番上に
//@use "../base/acmsTemplate.scss" as acms;
//と記述してください。


// ============================================================
//各ユニットのentry_contents内でのマージン処理設定
// ============================================================


// 変数定義
// ------------------------------------------------------------

$entryContentsBody_mb_PC  : 80px;
$entryContentsBody_mb_TAB : 48px;

//acms グリッドw   10px

$acms_grid_gap : 10px;
$acms_grid_gap_sp : 4.8%;
$design_grid_gap : 16px;
$desgin_form_grid_gap : 22px;

// 全体設定
// ------------------------------------------------------------

.entryContentsBody{
  margin-bottom: $entryContentsBody_mb_PC;
  @include mix.max-media(var.$screen-md){
    margin-bottom: $entryContentsBody_mb_TAB;
  }
}

.entryContentsBody{
  > *:first-child{
    margin-top: 0!important;
  }
  > hr:first-child + *{
    margin-top: 0!important;
  }
}

.entryContentsBody{
  [class*=acms-col]{
    @include mix.max-media(var.$screen-sm){
      padding-left: function.px2per(18,375);
      padding-right: function.px2per(18,375);
    }
  }
}

.entryContentsBody{
  .entry-container{
    @include mix.max-media(var.$screen-sm){
      margin-left: function.px2per(18,375);
      margin-right: function.px2per(18,375);
    }
  }
}



// ボタン関連
// ------------------------------------------------------------

//btn_group

.btn_group_center{

  [class*=btn]{
    margin: 0 12px;
  }

  .entryContentsBody &{
    width: calc(100% - 10px*2);
    margin: 2em 10px;
  }
}

.btn_group_left{

  [class*=btn]{
    margin: 0 12px;
  }

  [class*=btn]:first-child{
    margin-left: 0;
  }

  .entryContentsBody &{
    width: calc(100% - 10px*2);
    margin: 2em 10px;
  }

}

.btn_group_right{
  .entryContentsBody &{
    width: calc(100% - 10px*2);
    margin: 2em 10px;
  }
}



// heading用ミックスイン
//ablog cms内でheading0xなどのクラスを使用する場合はこのミックスインを適用してください。
// ------------------------------------------------------------

@mixin acmsHeadingGrid(){
  .entryContentsBody &{
    margin: 2.5em $acms_grid_gap 1.5em;
  }
  .entryContentsBody [class*=acms-col] &{
    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
    margin: 2.5em 0 1em;
  }
}



// ============================================================
//全体マージン処理のミックスイン
// ============================================================
@mixin resetEntryContentBodyMargin(){
  .entryContentsBody &{
    margin: 0 $acms_grid_gap 1.5em;

    @media screen and (var.$screen-sm) {
      margin-left: $acms_grid_gap_sp;
      margin-right: $acms_grid_gap_sp;
    }

  }
  .entryContentsBody [class*=acms-col] &{
    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
    margin: 0 0 1em;
  }
}
