@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//oneday_scheduleUnit
// ============================================================

.oneday_scheduleUnit{
  position: relative;
  padding-left: 4em;
  margin-bottom: 2.5em;

  &:last-child{
    margin-bottom: 0;
  }



  //時計アイコン 指定の自国の数字を入れるとアイコンが変わる

  @for $i from 7 through 21 {
    &.at_#{$i} {
      & .oneday_scheduleUnit_heading::before {
        background: url("../img/oneday/clock_at#{$i}.svg") no-repeat center center;
        background-size: cover;
      }
    }
  }
}

//contentsContainer

.oneday_scheduleUnit_contentsContainer{
  width: 100%;
  max-width: 100%;
  padding-right: 3em;

}

.oneday_scheduleUnit_heading{
  position: relative;
  font-family: var.$font-family-base;
  font-size: 20px;
  color: var.$brand-accent;
  border-bottom: 1px solid #898989;
  line-height: 1.1;
  padding-bottom: 8px;
  margin-bottom: 8px;

  &::before{
    content: "";
    position: absolute;
    top: 50%;
    left: -3em;
    transform: translateY(-50%);
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
  }
}


.oneday_scheduleUnit_time{
  font-family: var.$font-family-base;
  font-size: 14px;
  color: var.$brand-accent;
  line-height: 1.1;
  margin-bottom: 8px;
}

.oneday_scheduleUnit_img{
  width: 100%;
  max-width: 100%;
  margin-bottom: 8px;

  img{
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
}

