@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

$cell_color01: #FCE5D3;
$cell_color02: #FFF5C6;

//テーブルをスクロールさせたい場合は、親要素に.scrollableTableをつけてください。

.scrollableTable{
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 8px;
}


.table01{
  font-family: var.$font-family-rounded;
  font-size: 16px;


  th{
    padding: 8px 0;
    border: 2px solid #000;

  }

  td{
    padding: 8px 0;
    text-align: center;
    vertical-align: middle;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;

    &.cellOpacity_0{
      opacity: 0;
      border: none;
    }
  }


  .scrollableTable &{
    min-width: 1200px;
  }
}

.cellColor01{
  background-color: $cell_color01;
}

.cellColor02{
  background-color: $cell_color02;
}

.cellColorWhite{
  background-color: #fff;
}


.table_caution{
  font-family: var.$font-family-rounded;
  font-size: 12px;
  line-height: 1.3;
  text-align: right;
  color: var.$text-color;

  &_left{
    font-family: var.$font-family-rounded;
    font-size: 12px;
    line-height: 1.3;
    text-align: left;
    color: var.$text-color;
  }
}

.confirmTable{
  width: 100%;
  max-width: 600px;
  margin: 0 auto 24px;

  th{
    width: 30%;
  }

  td{
    width: 70%;
  }

  th,td{
    @include mix.max-media(var.$screen-sm){
      display: block;
    }
    
  }
}