@use "../../variables" as var;
@use "../../mixins" as mix;
@use "../../functions" as function;


//  グリッドシステム　間隔
//===============================

// ** a-blog cmsのグリッドシステムの仕組み
//acms.cssのグリッドシステムでは、各カラムにpadding左右10pxつけ、
//はみ出した左右10pxを、左右をmargin-10px（ネガティブマージンと呼ばれます）
//を設定したコンテナーを全体に適応し、相殺することで成立しています。

$grid-space           : 20px; //左右10px空ける
 $negative-grid-space:        -10px; //左右-10pxする

//モバイルのとき
$mobile-grid          : 100%; //幅を100%にする
$mobile-grid-space    : 0; //10px空いていたのを0にする
// $mobile-negative-grid-space: 0; //-10pxしていたのを0にする

// ============================================================
//ミックスイン
// ============================================================

// ------------------------------
// grid
// ------------------------------
@mixin make-grid($name,$grid-name,$number){
  @for $i from 1 through $number{
    @if $grid-name{
      .#{$name}-#{$grid-name}-#{$i}{
        @if $i != $number {
          float: left;
        } @else{
          float: none;
        }
        width: percentage($i / $number);
      }
    } @else {
      .#{$name}-#{$i}{
        @if $i != $number {
          float: left;
        } @else{
          float: none;
        }
        width: percentage($i / $number);
      }
    }
  }
}

@mixin make-pulled-grid($name,$grid-name,$number){
  $limit : $number - 1;
  @for $i from 1 through $limit{
    @if $grid-name{
      .#{$name}-#{$grid-name}-#{$i}{
        right: percentage($i / $number);
      }
    } @else {
      .#{$name}-#{$i}{
        right: percentage($i / $number);
      }
    }
  }
}

@mixin make-pushed-grid($name,$grid-name,$number){
  $limit : $number - 1;
  @for $i from 1 through $limit{
    @if $grid-name{
      .#{$name}-#{$grid-name}-#{$i}{
        left: percentage($i / $number);
      }
    } @else {
      .#{$name}-#{$i}{
        left: percentage($i / $number);
      }
    }
  }
}




// ------------------------------
//  グリッドシステム
// ------------------------------

//コンテナー
$acms-container    : 980px;

// グリッドシステムの合計のカラム数
$acms-columns      : 12;

// 各カラムの幅
$acms-col-1        : percentage(1 / $acms-columns);
$acms-col-2        : percentage(2 / $acms-columns);
$acms-col-3        : percentage(3 / $acms-columns);
$acms-col-4        : percentage(4 / $acms-columns);
$acms-col-5        : percentage(5 / $acms-columns);
$acms-col-6        : percentage(6 / $acms-columns);
$acms-col-7        : percentage(7 / $acms-columns);
$acms-col-8        : percentage(8 / $acms-columns);
$acms-col-9        : percentage(9 / $acms-columns);
$acms-col-10       : percentage(10 / $acms-columns);
$acms-col-11       : percentage(11 / $acms-columns);
$acms-col-12       : percentage(12 / $acms-columns);

// ------------------------------
//  Media Queries
// ------------------------------

//各種ブレイクポイント
// $xl-width : 1224px;
// $lg-width : 1024px;
// $md-width : 768px;
// $sm-width : 480px;

// max-widthの値も用意していますが、基本的にはmin-widthの値を使っています。
// よりコードの記述がシンプルになる場合はmax-widthを使います。

//min-width指定
$breakpoint-sm-min    : 480px;
$breakpoint-md-min    : 768px;
$breakpoint-lg-min    : 1024px;
$breakpoint-xl-min    : 1440px;

//max-width指定
$breakpoint-sm-max    : ($breakpoint-sm-min - 1); //479px
$breakpoint-md-max    : ($breakpoint-md-min - 1); //767px
$breakpoint-lg-max    : ($breakpoint-lg-min - 1); //1023px
$breakpoint-xl-max    : ($breakpoint-xl-min - 1); //1439px



/* ------------------------------
 グリッドシステム
------------------------------- */
// コンテナー
.acms-container{
  max-width: $acms-container;
  margin-right: auto;
  margin-left: auto;
  padding-right: ($grid-space / 2);
  padding-left: ($grid-space / 2);
}



// ---------------
// グリッドシステムの全体を囲むコンテナー
// ---------------

.acms-grid,
.acms-grid-r {
  margin-right: ($grid-space / -2);
  margin-left: ($grid-space / -2);
  @include mix.clearfix();
}

//.acms-grid-negativeは左右-10pxになって欲しくないときに使用する
.acms-grid-negative {
  margin-right: ($mobile-grid-space / -2);
  margin-left: ($mobile-grid-space / -2);
}

//各acms-col-の左右の幅
[class*='acms-col'] {
  min-height: 1px;
  padding-right: ($grid-space / 2); //各col-はpaddingを左右に着ける
  padding-left: ($grid-space / 2); //各col-はpaddingを左右に着ける
}

@media (min-width : $breakpoint-md-min) {
  .acms-grid,
  .acms-grid-r {
    margin-right: ($grid-space / -2);
    margin-left: ($grid-space / -2);
  }
  [class*='acms-col'] {
    padding-right: ($grid-space / 2); //各col-はpadding左右に着ける
    padding-left: ($grid-space / 2); //各col-はpadding左右に着ける
  }
}

[class*='acms-col'],
[class*='acms-col']:before,
[class*='acms-col']:after {
}


// ---------------
// 幅
// ---------------
// 1カラム
@include make-grid(acms-col,null,$acms-columns);

// ---------------
// pushとpull
// ---------------
[class*='acms-push'],
[class*='acms-pull']{
  position: relative;
}
// ---------------
// push 左の位置
// ---------------
@include make-pushed-grid(acms-push,null,$acms-columns);

// ---------------
// pull 右の位置
// ---------------
@include make-pulled-grid(acms-pull,null,$acms-columns);


//---------------
//小幅のグリッド
//---------------
@media (min-width : $breakpoint-sm-min)  {
  // カラム
  @include make-grid(acms-col,sm,$acms-columns);

  // ---------------
  // push 左の位置
  // ---------------
  [class*='acms-push-sm'] {
    position: relative;
    right: auto;
  }
  // pushしない
  .acms-push-sm-auto{
    left: auto;
  }
  @include make-pushed-grid(acms-push,sm,$acms-columns);

  // ---------------
  // pull 右の位置
  // ---------------
  [class*='acms-pull-sm'] {
    position: relative;
    left: auto;
  }
  // pullしない
  .acms-pull-sm-auto{
    right: auto;
  }
  @include make-pulled-grid(acms-pull,sm,$acms-columns);
}

//---------------
//中くらい幅のグリッド
//---------------
@media (min-width : $breakpoint-md-min) {

  @include make-grid(acms-col,md,$acms-columns);
  // ---------------
  // push 左の位置
  // ---------------
  [class*='acms-push-md'] {
    position: relative;
    right: auto;
  }
  // pushしない
  .acms-push-md-auto{
    left: auto;
  }
  @include make-pushed-grid(acms-push,md,$acms-columns);
  // ---------------
  // pull 右の位置
  // ---------------
  [class*='acms-pull-md'] {
    position: relative;
    left: auto;
  }
  // pullしない
  .acms-pull-md-auto{
    right: auto;
  }
  @include make-pulled-grid(acms-pull,md,$acms-columns);
}



//---------------
//デスクトップ幅（1024px以上）のグリッド
//---------------
@media (min-width : $breakpoint-lg-min) {

  @include make-grid(acms-col,lg,$acms-columns);

  // ---------------
  // push 左の位置
  // ---------------

  [class*='acms-push-lg'] {
    position: relative;
    right: auto;
  }
  // pushしない

  .acms-push-lg-auto{
    left: auto;
  }
  @include make-pushed-grid(acms-push,lg,$acms-columns);

  // ---------------
  // pull 右の位置
  // ---------------
  [class*='acms-pull-lg'] {
    position: relative;
    left: auto;
  }
  // pullしない
  .acms-pull-lg-auto{
    right: auto;
  }
  @include make-pulled-grid(acms-pull,lg,$acms-columns);
}

//---------------
//デスクトップ幅（1440px以上）のグリッド
//---------------
@media (min-width : $breakpoint-xl-min) {
  @include make-grid(acms-col,xl,$acms-columns);
  // ---------------
  // push 左の位置
  // ---------------
  [class*='acms-push-xl'] {
    position: relative;
    right: auto;
  }
  // pushしない
  .acms-push-xl-auto{
    left: auto;
  }
  @include make-pushed-grid(acms-push,xl,$acms-columns);
  // ---------------
  // pull 右の位置
  // ---------------
  [class*='acms-pull-xl'] {
    position: relative;
    left: auto;
  }
  // pullしない
  .acms-pull-xl-auto{
    right: auto;
  }
  @include make-pulled-grid(acms-pull,xl,$acms-columns);
}

// ---------------
// エントリー(.acms-entry)内グリッド
// ---------------

.acms-entry .acms-col-12 {
  clear: both;
}


