@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.textUnit{
  p{
    font-size: 20px;
    font-family: var.$font-family-rounded;
    font-weight: 500;
    margin-bottom: 12px;

    &:last-child{
      margin-bottom: 0;
    }

    @include mix.max-media(var.$screen-sm){
      font-size: 15px;
    }
  }

  &.center{
    text-align: center;
    @include mix.max-media(var.$screen-sm){
      text-align: left;
    }
  }
}


// ============================================================
// 各ページ固有
// ============================================================

.contents_introduce_textUnit{
  margin-bottom: 36　px;
}

.section_textUnit{
  margin-bottom: 24px;
}