@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

$header_btn_color :#595757;


.btn_group_center{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.btn01{
  @include mix.resetBtnStyle();
  @include mix.setLinkHoverFadeAnime();
  display: block;
  text-decoration: none;
  font-family: var.$font-family-rounded;
  font-weight: bold;
  color: var.$text-color-green;
  background-color: #ffffff;
  border-radius: 14px;
  padding: 8px 0;
  text-align: center;
  max-width: 280px;

  &:active,
  &:link,
  &:visited,
  &:focus,
  &:hover{
    color: var.$text-color-green;
  }
}

.contactBtn{
  @include mix.resetBtnStyle();
  @include mix.setLinkHoverFadeAnime();
  @include mix.setTextLinkColor(var.$text-color);
  display: block;
  text-decoration: none;
  font-family: "Material Icons Outlined";
  font-size: 30px;
  color: $header_btn_color;
  border-radius: 50%;
  border: 4px solid $header_btn_color;
  padding: 5px;

  @include mix.max-media(var.$screen-sm){
    font-size: 16px;
    border: 2px solid $header_btn_color;
  }
}

.priceBtn{
  @include mix.setLinkHoverFadeAnime();
  @include mix.setTextLinkColor(var.$text-color);
  display: block;
  width: 100%;
  text-decoration: none;
  font-family : var.$font-family-rounded;
  font-weight: bold;
  text-align: center;
  background: #fff;
  font-size : 20px;
  padding: 16px 0;
  margin-bottom: 12px;
}

//ttop_sectionLink_btn

.top_sectionLink_btn{
  &:active,
  &:link,
  &:visited,
  &:focus,
  &:hover{
    text-decoration: none;
  }
}

//フォーム送信ボタン

.contact_submitBtn{
  @include mix.resetBtnStyle();
  @include mix.setLinkHoverFadeAnime();
  display: block;
  width: 100%;
  background-color: var.$brand-accent;
  color: var.$text-color-white;
  text-decoration: none;
  font-family : var.$font-family-rounded;
  font-weight: bold;
  text-align: center;
  font-size : 20px;
  padding: 12px 0;
  margin-bottom: 12px;
  border-radius: 8px;

  @include mix.min-media(var.$screen-md){
    max-width: 500px;
    margin: 0 auto;
  }
}

