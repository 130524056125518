@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

/* ============================================================ //
// @ form
// ============================================================ */



.contactForm{
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 36px 0;
  background: transparent;
}

.contactForm_item{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;
  column-gap: 16px;
  width: 100%;
  margin-bottom: 24px;

  @include mix.max-media(var.$screen-sm){
    column-gap: 8px;
  }

  fieldset{
    display: contents;
  }

  input,textarea{

    &:focus{
      outline: 2px solid var.$brand-accent;
      border: none;
    }
  }
}

.contactForm_item.checkbox{
  justify-content: center;
}

.contactForm_item.textarea{
  .contactForm_label{
    padding-top: 8px;
    align-self: start;
  }
}

.contactForm_label{
  font-family: var.$font-family-base;
  font-weight: bold;
  font-size: 18px;
  color: var.$text-color;
  text-align: left;
  min-width: 200px;

  @include mix.max-media(var.$screen-sm){
    min-width: 110px;
    font-size: 14px;
  }
}

.contactForm_input{
  width: calc(100% - 216px);
  font-family: var.$font-family-base;
  color: var.$text-color;
  font-size: 18px;
  padding : 12px 16px;
  border: 1px solid #000;
  border-radius: 5px;

  @include mix.max-media(var.$screen-sm){
    width: calc(100% - 126px);
    padding: 8px 12px;
  }

  &::placeholder{
    color: #ccc;
  }
}

textarea.contactForm_input{
  min-height: 500px;

  @include mix.max-media(var.$screen-sm){
    min-height: 200px;
  }
}




$img_path: "./../../assets/img/";

.form-group {
  margin-bottom: 0;
}

.label--required {
  float: right;
  display: inline-block;
  position: relative;
  top: -2px;
  border-radius: 10px;
  background: var.$brand-error;
  margin-top: 4px;
  padding: .4em .5em;
  line-height: 1;
  font-size: 12px;
  color: #fff;
  font-weight: normal;
}
.label--any {
  float: right;
  display: inline-block;
  position: relative;
  top: -2px;
  border-radius: 10px;
  background: var.$brand-info;
  margin-top: 4px;
  padding: .4em .5em;
  line-height: 1;
  font-size: 12px;
  color: #fff;
  font-weight: normal;
}

.fld-agree__text {
  font-size: 16px;
  font-weight: normal;
}

/* ============================================================ //
// @ form-control Container
// ============================================================ */

.form-control-container{}

.form-control-body-inner{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.form-control-body-inner--column{
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
}


/* ============================================================ //
// @ form-control width
// ============================================================ */

// @ フォームグリッド
// ------------------------------

.form-w10p {
  @include mix.min-media(var.$screen-lg) {
    width: 10%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 10%;
  }
}

.form-w20p {
  @include mix.min-media(var.$screen-lg) {
    width: 20%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 20%;
  }
}

.form-w30p {
  @include mix.min-media(var.$screen-lg) {
    width: 30%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 30%;
  }
}

.form-w35p {
  @include mix.min-media(var.$screen-lg) {
    width: 35%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 35%;
  }
}

.form-w40p {
  @include mix.min-media(var.$screen-lg) {
    width: 40%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 40%;
  }
}

.form-w50p {
  @include mix.min-media(var.$screen-lg) {
    width: 50%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 50%;
  }
}

.form-w60p {
  @include mix.min-media(var.$screen-lg) {
    width: 60%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 60%;
  }
}

.form-w70p {
  @include mix.min-media(var.$screen-lg) {
    width: 70%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 70%;
  }
}

.form-w75p {
  @include mix.min-media(var.$screen-lg) {
    width: 75%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 75%;
  }
}

.form-w80p {
  @include mix.min-media(var.$screen-lg) {
    width: 80%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 80%;
  }
}

.form-w90p {
  @include mix.min-media(var.$screen-lg) {
    width: 90%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 90%;
  }
}

.form-w100p {
  width: 100%;
  @include mix.min-media(var.$screen-lg) {
    width: 100%;
  }
  @include mix.max-media(var.$screen-md) {
    width: 100%;
  }
}

/* ============================================================ //
// @ 状態クラス
// ============================================================ */


.form-control--disable{
  background: #eee !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
  &:hover{
    border-color:#ccc !important;
    cursor: not-allowed !important;
  }
}

/* ============================================================ //
// @ form-control
// ============================================================ */

// input textarea に付与する
/*doc
---
title:
name:
category:
---

｀｀｀html_example

<th>
  <p class="contact-tabel__title">メールアドレス</p>
  <p class="contact-tabel__lead open-sans-bold">Mail address</p>
</th>
<td>[tel your-mail class:form-control]</td>｀


*/

@mixin form-control-input{
  margin: 0;
  outline: none;
  //appearance
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  border: 1px solid var.$input-border;
  padding: var.$input-pad;
  vertical-align: middle;
  border-radius: var.$input-border-radius;
  color: var.$input-color;
  background: var.$input-bg;
  font-size: 15px;
  &:focus,
  &:hover {
    border-color: var.$brand-primary;
  }
  &:active {
    border-color: var.$brand-primary;
  }
  &:focus {
    outline: 0;
    border-color: var.$brand-primary;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05), 0 0 8px rgba(204, 204, 204, .3);
  }
  &::-webkit-input-placeholder {
    color: var.$text-color-placeholder;
  }
  &::-moz-input-placeholder {
    color: var.$text-color-placeholder;
  }
  &::-ms-input-placeholder {
    color: var.$text-color-placeholder;
  }
  &::placeholder{
    color: #ccc;
  }
}

.form-control {
  @include form-control-input;
}

// ------------------------------------------------------------
// @ select
// ----------------------------------------------------------&--

/*doc
---
title:
name:
category:
---

｀｀｀html_example

<tr>
  <th>お問い合わせ項目</th>
  <td><div class="select"><label>[select fld_category id:fld_category  class:form-w50p "お見積り" "ご注文" "製品について" "採用" "その他"]</label></div></td>
</tr>｀｀｀


*/

@mixin form-control-select{

}

select{
  border: 1px solid #ccc;
  color: var.$text-color;
  background: #fff;
  height: 38px;
}

.form-control-select {
  display: inline-block;
  position: relative;
  select {
    // @ リセット
    // ----------------------------
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    //border-radius: 0;
    //border: 0;
    margin: 0;
    //padding: 0;
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    //firefox
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    //for ie
    &::-ms-expand {
      display: none;
    }

    // @ スタイル
    // ------------------------------
    display: block;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    border: 1px solid var.$input-border;
    border-radius: var.$input-border-radius;
    padding: .4em 2.4em .4em .6em;
    color: var.$text-color;
    background: #fbfbfb;
    &:focus,
    &:hover {
      outline: 0;
      border-color: var.$brand-primary;
    }
    &:active {
      border-color: var.$brand-primary;
    }
    &:focus {
      outline: 0;
      border-color: var.$brand-primary;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05), 0 0 8px rgba(204, 204, 204, .3);
    }
    &:after {
      display: block;
      content: '';
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: var.$brand-primary transparent transparent transparent;
    }
  }
  &:before {
    z-index: 2;
    display: inline-block;
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0%, -50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color: var.$text-color transparent transparent transparent;
    margin-top: 6px;
  }
  &:after {
    z-index: 2;
    display: inline-block;
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -6px;
    transform: translate(0%, -50%) rotate(180deg);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color: var.$text-color transparent transparent transparent;
  }
}


.select-label{
  padding: 0 6px;
}


// ------------------------------------------------------------
// @ radio
// ------------------------------------------------------------

/*doc
---
title: ラジオボタン
name: raido
category: form
---

```html_example

// use contact form7
[radio radio-343 class:radio use_label_element default:1 "同意する" " 同意しない"]

<label>
  <input type="radio" name="radio-343" value="同意する" checked="checked">
  <span class="wpcf7-list-item-label">同意する</span>
</label>

```
*/

// @  radio
// ------------------------------
input[type=radio], input[type=radio]:checked {
  display: none !important;
}

.radio {
  input[type=radio] + span {
    box-sizing: border-box;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    position: relative;
    display: inline-block;
    margin: 0 20px 0 0;
    padding: 0 0 0 2.3em;
    vertical-align: middle;
    cursor: pointer;
  }
  input[type=radio] + span:hover:after {
    border-color: var.$input-active-color;;
  }
}

.radio {
  input[type=radio] + span:after {
    @include mix.transitionDelay;
  
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    margin-top: -12px;
    width: 20px;
    height: 20px;
    border: 1px solid #bbb;
    border-radius: 50%;
    background: #fff;
  }
  input[type=radio] + span:before {
    z-index: 2;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    position: absolute;
    top: 50%;
    left: 6px;
    display: block;
    margin-top: -6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var.$input-active-color;;
    content: '';
    opacity: 0;
  }
  input[type=radio]:checked + span:before,
  input[type=radio]:checked + span:after {
    opacity: 1;
    border-color: var.$input-active-color;;
  }
}

// ------------------------------------------------------------
// @ checkbox
// ------------------------------------------------------------

/*doc
---
title: チェックボックス
name: checkbox
category: form
---

```html_example

// use contact form7
[checkbox* checkbox-678 class:checkbox use_label_element "hoge" "fuga" "piyo"]

<label>
  <input type="checkbox" name="checkbox-678[]" value="高磁力型マグネット式チップコンベア" data-com.agilebits.onepassword.user-edited="yes">
  <span class="wpcf7-list-item-label">hoge</span>
</label>

```
*/

input[type=checkbox], input[type=checkbox]:checked {
  display: none !important;
}

.checkbox {
  input[type=checkbox] + span {
    box-sizing: border-box;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    position: relative;
    display: inline-block;
    margin: 0 20px 0 0;
    padding: 0 0 0 2.3em;
    vertical-align: middle;
    cursor: pointer;
  }
  input[type=checkbox] + span:hover:after {
    border-color: var.$input-active-color;;
  }
}

.checkbox {
  input[type=checkbox] + span:after {
    @include mix.transitionDelay;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    margin-top: -12px;
    width: 20px;
    height: 20px;
    border: 1px solid #bbb;
    border-radius: 3px;
    background: #fff;
  }
  input[type=checkbox] + span:before {
    z-index: 2;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    transform: rotate(-45deg);
    position: absolute;
    top: 50%;
    left: 5px;
    display: block;
    margin-top: -7px;
    width: 10px;
    height: 6px;
    border-bottom: solid 2px var.$input-active-color;;
    border-left: solid 2px var.$input-active-color;;
    content: '';
    opacity: 0;
  }
  input[type=checkbox]:checked {
    display: block;
    background: #000;
  }
  input[type=checkbox]:checked + span:before,
  input[type=checkbox]:checked + span:after {
    opacity: 1;
    border-color: var.$input-active-color;;
  }
}

/* ============================================================ //
// @ form-table
// ============================================================ */

.form-table {
  th {
    text-align: left;
  }
}

/* ============================================================ //
// @ validationEngine.jquery.css
// ============================================================ */

/* Z-INDEX */
.formError {
  z-index: 990;
}

.formError .formErrorContent {
  z-index: 991;
}

.formError .formErrorArrow {
  z-index: 996;
}

.ui-dialog .formError {
  z-index: 5000;
}

.ui-dialog .formError .formErrorContent {
  z-index: 5001;
}

.ui-dialog .formError .formErrorArrow {
  z-index: 5006;
}

.inputContainer {
  position: relative;
  float: left;
}

.formError {
  position: absolute;
  top: 300px;
  left: 300px;
  display: block;
  cursor: pointer;
  text-align: left;
}

.formError.inline {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
}

.ajaxSubmit {
  padding: 20px;
  background: #55ea55;
  border: 1px solid #999;
  display: none;
}

.formError .formErrorContent {
  width: 100%;
  background: #ee0101;
  position: relative;
  color: #fff;
  min-width: 120px;
  font-size: 13px;
  opacity: .8;
  //box-shadow: 0 0 6px #000;
  //-moz-box-shadow: 0 0 6px #000;
  //-webkit-box-shadow: 0 0 6px #000;
  //-o-box-shadow: 0 0 6px #000;
  padding: .5em 1em;
  border-radius: 3px;
}

.formError.inline .formErrorContent {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  border: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -o-border-radius: 0;
}

.greenPopup .formErrorContent {
  background: #33be40;
}

.blackPopup .formErrorContent {
  background: #393939;
  color: #FFF;
}

// @ form-step
// ------------------------------------------------------------ */

.form-step {
  margin-bottom: 10px;

  &__inner {
    width: 100%;
    display: flex;
    -js-display: flex;
    flex-flow: row wrap;
    background: #F2F2F2;
    color: #2B85DE;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    overflow: hidden;
  }

  &__item {
    width: calc(100% / 3);
    border-right: #ccc 1px solid;
    text-align: center;

    &:last-of-type {
      border-right: none;
    }

  }

  .is-active {
    position: relative;
    padding: 10px 5px;
    background: var.$brand-primary;
    color: #fff;

    &:before {
      position: absolute;
      top: 50%;
      right: 1px;
      transform: translate(110%, -50%);
      content: "";
      border-top: 22px solid transparent;
      border-bottom: 22px solid transparent;
      border-left: 5px solid var.$brand-primary;
    }
  }

  .no-active {
    padding: 10px 5px;

  }
}

// @ form-box
// ------------------------------------------------------------ */

.form-box {
  margin: 0 0 20px;
  padding: 20px;
  background: #F2F2F2;

  @include mix.max-media(var.$screen-sm) {
    padding: 15px;
  }

  p {
    display: inline-block;
    margin: 0;
    font-size: 15px;

    @include mix.max-media(var.$screen-sm) {
      font-size: 12px;

    }
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: var.$brand-error;

    @include mix.max-media(var.$screen-sm) {
      font-size: 13px;
    }
  }
}


.form-box{
  height: 200px;
  max-height: 200px;
  overflow: scroll;
  > p{
    margin-bottom: 1em;
  }
  @include mix.max-media(var.$screen-md){
    height: 130px;
    max-height: 130px;
  }
}

// ============================================================
// フォーム
// ============================================================

// ============================================================
// フォーム 確認画面用 テーブル
// ============================================================
//
// <div class="form-confirm-table-container">
//   <table class="table01">
//   ....
//   </talbe>
// </div>
//

.form-confirm-table-container{
  .table01 th{
    width: 40%;
    line-height: 1.4;
    font-size: 15px;
  }
}

.form-confirm-annotation-text{
  font-size: 13px;
  color: #ccc;
}

// ============================================================
// フォーム 戻る/送信ボタン
// ============================================================

.form-submit-btn-group{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.form-submit-btn-item{
  margin: 0 5px 5px;
  font-weight: 600;
  padding: 14px 34px;
  font-size: 16px;
  width: 200px;
  text-align: center;
  justify-content: center;
  @include mix.max-media(var.$screen-sm){
    margin: 0 10px 5px;
  }
}