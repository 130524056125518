@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.priceEndText{
  font-family: var.$font-family-rounded;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #22AC38;
  line-height: 1.1;
}