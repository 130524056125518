@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;



$header_pad_SP : 20px;


.header{
  position: fixed;
  z-index: var.$zindex_header;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  background: var.$brand-main;
  height: var.$header_H_PC;

  @include mix.max-media(var.$screen-sm){

    height: var.$header_H_SP;
  }
}

// 下層ページヘッダー
// ============================================================

.page_header{
  .header_inner:before{
    display: none;
  }
}

.header_inner{
  position: relative;
  z-index: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center ;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  @include mix.max-media(var.$screen-mlg){
    padding: 0 $header_pad_SP;
  }
  @include mix.max-media(var.$screen-sm){
    padding: 0 $header_pad_SP;
  }

  &:before{
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    width: 150px;
    height: 150px;
    background: var.$brand-main;
    border-radius: 50%;
    
  }
}

.header_logo{
  width: 100%;
  max-width: 78px;

  a{
    @include mix.setLinkHoverFadeAnime();
    display: block;
    width: 100%;
    height: 100%;
  }

  @include mix.max-media(var.$screen-sm){
    max-width: 65px;
    padding-top: 5px;
  }
}

.header_logo02{
  @extend .header_logo;
  max-width: 70px;
  @include mix.max-media(var.$screen-sm){
    max-width: 50px;
  }
}

.contactUnit{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  //
  position: absolute;
  top: 0;
  right: $header_pad_SP;
  bottom: 0;
}
