@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


.sectionLogo{
  max-width: 70px;
  margin: 0 auto 24px;

  img{
    width: 100%;
    max-width: 100%;
  }
}

.sectionLogo02{
  max-width: 150px;
  margin: 0 auto 24px;

  img{
    width: 100%;
    max-width: 100%;
  }
}

.sectionImg{
  width: 100%;
  max-width: 100%;
  margin-bottom: 24px;

  img{
    width: 100%;
    max-width: 100%;
  }

a{
  @include mix.setLinkHoverFadeAnime();
}
}


//各下層ページへのリンクセクション

.top_sectionLinkUnitGroup{

  @include mix.min-media(var.$screen-5ls){
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    column-gap: 12px;
  }
}

.top_sectionLinkUnit{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: stretch;
  column-gap: 24px;
//
  width: 100%;
  max-width: 100%;
  padding: 0 12px;
  margin-bottom: 36px;

  @include mix.min-media(var.$screen-5ls){
    flex-flow: column;
    width: calc((100% - 24px) / 3);
  }

  &.isReverse{
    flex-flow: row-reverse wrap;
    @include mix.min-media(var.$screen-5ls){
      flex-flow: column;
    }
  }

}

.top_sectionLinkImg{
  width: 45%;
  margin-bottom: 12px;

  @include mix.min-media(var.$screen-5ls){
    width: 100%;
  }

  img{
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

.top_sectionLink_headingGroup{
  width: calc(100% - 45% - 24px);

  @include mix.min-media(var.$screen-5ls){
    width: 100%;
  }
}

.top_sectionLink_heading{
  font-family: var.$font-family-rounded;
  font-size: 24px;
  font-weight: bold;
  color: var.$text-color-green;
  line-height: 1.1;
  margin-bottom: 8px;
}


.top_sectionLink_number{
  position: relative;
  line-height: 1.1;

  &:after{
    display: inline-block;
    content: "";
    width: 4px;
    height: 4px;
    background: var.$text-color-green;
  }
}


.top_sectionLink_lead{
  font-family: var.$font-family-rounded;
  font-size: 16px;
  color: var.$text-color;
  line-height: 1.4;
  margin-bottom: 12px;
  
  @include mix.min-media(var.$screen-5ls){
    min-height: 45px;
  }
}





//news_section
//1stは画像対応
// ============================================================

.top_newsIndexGroup{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;
  column-gap: 24px;
  row-gap: 56px;
  margin-bottom: 56px;
}

.top_newsIndexCard{
  position: relative;
  width: calc((100% - 24px) / 2);

  a{
    @include mix.setLinkHoverFadeAnime();
    text-decoration: none;
  }
}

.top_newsIndexImg{
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 112px;
  margin-bottom: 12px;


  @include mix.min-media(var.$screen-md-min){
    max-height: none;
  }

  img{
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

.top_newsIndexCategory{
  position: absolute;
  top: -5%;
  left: -3%;
  padding: 4px 8px;
  font-family: var.$font-family-rounded;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  color: var.$text-color-white;
  min-width: 60px;

  @include mix.min-media(var.$screen-md-min){
    font-size: 14px;
    min-width: 80px;
  }

  &.forNews{
    background: var.$text-color-green;
  }

  &.forDiary{
    background: #F39800;
  }

}

.top_newsIndexTitleGroup{
  position: absolute;
  left: 50%;
  bottom: -50%;
  transform : translate(-50%,-50%);
  width: 90%;
  max-width: 100%;
  padding: 12px;
  background: #fff;
  
  @include mix.min-media(var.$screen-md-min){
    bottom: -20%;
  }
}

.top_newsIndexDate{
  display: inline-block;
  font-family: var.$font-family-rounded;
  color: var.$text-color-sub;
  font-size: 10px;
  margin-bottom: 8px;

  @include mix.min-media(var.$screen-md-min){
    font-size: 12px;
  }
}

.top_newsIndexTitle{
  font-family: var.$font-family-rounded;
  font-size: 12px;
  font-weight: normal;
  color: var.$text-color;
  line-height: 1.4;

  @include mix.min-media(var.$screen-md-min){
    font-size: 16px;
  }
}

//
//.news_imgGroup{
//  display: flex;
//  flex-flow: row wrap;
//  justify-content: start;
//  align-items: center;
//  column-gap: 12px;
//  row-gap: 12px;
//  width: 100%;
//  max-width: 100%;
//  margin-bottom: 28px;
//}
//
//.news_img{
//  width: calc((100% - 12px) / 2);
//
//  img{
//    width: 100%;
//    max-width: 100%;
//  }
//}

.news_indexMore{
  @include mix.setTextLinkColor(var.$text-color);
  @include mix.setLinkHoverFadeAnime();
  display: block;
  text-decoration: none;
  font-family: var.$font-family-rounded;
  //color: var.$text-color;
  text-align: center;

  &:before{
    display: inline-block;
    content: url("../img/common/square.svg");
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
}



